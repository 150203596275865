
import { reactive, defineComponent, computed, ref, watch } from "vue";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import useClientDetail from "@/core/services/compositions/client/useClientDetail";
import { useI18n } from "vue-i18n";
import { translate } from "@/core/helpers/translate";
import {
  showErrorPopup,
  showSuccessPopup,
  useQuery,
  useMutateQuery,
} from "@/core/helpers/common-helper";
import moment from 'moment';
import { checkPermission } from '@/router/authentication';
import ApiService from "@/core/services/ApiService";
import useChannel from "@/core/services/compositions/enum/useChannel";
import useGateway from "@/core/services/compositions/enum/useGateway";
import useAuthFlow from "@/core/services/compositions/enum/useAuthFlow";

interface EditClient {
  type: string;
  name: string;
  email: string;
  channel: string;
  gateway: string;
  // webhook_url: string;
  // allowed_ip: string;
}

interface EditApi {
  id: string;
  secret: string;
  api_token: string;
}

interface EditWebhookConfig {
  client_code: string;
  webhook_auth_flow: string;
  webhook_auth_config: Array<any>;
}

export default defineComponent({
  components: {
    // Pagination,
    // TableSkeleton,
  },
  setup() {
    // init
    const { t } = useI18n();
    const route = useRoute();
    const dialogClientVisible = ref(false);
    const dialogApiVisible = ref(false);
    const dialogWebhookVisible = ref(false);
    const dialogCredentialsVisible = ref(false);
    const progressUpdate = ref(false);
    const credentialsType = ref();
    const submitButton = ref<null | HTMLButtonElement>(null);
    const { query } = useQuery();

    const { channel } = useChannel();
    const { gateway } = useGateway();
    const { authFlow } = useAuthFlow();

    // breadcrumb
    setNewPageBreadcrumbs(t("management.client.title"), [
      { title: t("management.client.title") }
    ]);

    const editClient = reactive<EditClient>({
      type: '',
      name: '',
      email: '',
      channel: '',
      gateway: '',
      // webhook_url: '',
      // allowed_ip: '',
    });

    const editApi = reactive<EditApi>({
      id: '',
      secret: '',
      api_token: '',
    });

    const editWebhookConfig = reactive<EditWebhookConfig>({
      client_code: '',
      webhook_auth_flow: '',
      webhook_auth_config: [],
    });

    const clientHash = route.params.id;

    const { data: detail, mutate: refreshList } = useClientDetail(clientHash);

    watch(detail, (response) => {
      if (response?.rc == 'SUCCESS') {
        editClient.name = response?.data.name;
        editClient.email = response?.data.email;
        editClient.type = response?.data.clientable_type;

        if (editClient.type == 'channel') {
          editClient.channel = response?.data.clientable.channel
        } else {
          editClient.gateway = response?.data.clientable.key
        }
        // editClient.webhook_url = response?.data.webhook_url;
        // editClient.allowed_ip = response?.data.clientable_allowed_ip;

        editApi.id = response?.data.api_credentials.id;
        editApi.secret = response?.data.api_credentials.secret;
        editApi.api_token = response?.data.api_token;

        editWebhookConfig.client_code = response?.data.client_code;
        editWebhookConfig.webhook_auth_flow = response?.data.webhook_auth_flow;
        editWebhookConfig.webhook_auth_config = response?.data.webhook_auth_config;
      }
    });

    const fetchParams = computed(() => ({
      page: query.value.page || 1,
      limit: query.value.limit || 20,
    }));

    // const { data, isValidating, mutate: refreshList, error } = useClientList(() => fetchParams.value);

    const { mutateQuery } = useMutateQuery();
    const handleChangePage = (nextPage: number) => {
      mutateQuery({
        page: nextPage,
      });
    };

    const showDialogClient = () => {
      dialogClientVisible.value = true;
    }

    const showDialogApi = () => {
      dialogApiVisible.value = true;
    }

    const showDialogWebhook = () => {
      dialogWebhookVisible.value = true;
    }

    const showDialogCredentials = (type) => {
      dialogCredentialsVisible.value = true;
      credentialsType.value = type;
    }

    const submitUpdateClient = () => {
      dialogClientVisible.value = false;

      if (editClient.name == null || editClient.name == '') {
        showErrorPopup({
          title: 'Error',
          text: '[Transaction Note] cannot empty',
          message: '[Transaction Note] cannot empty',
        })

        return;
      }

      submitButton.value ? (submitButton.value.disabled = true) : null;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      const payload = {
        clientable: editClient.type == 'channel' ? editClient.channel : editClient.gateway,
        name: editClient.name,
        email: editClient.email,
      };

      ApiService.setHeader();
      ApiService.put(
        `app/entity/client/${clientHash}`,
        payload as any
      )
        .then(() => {
          showSuccessPopup({
            title: 'Update Status',
            text: 'Success'
          });

          // refreshList();

        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            for (const key in response.data.data) {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.data[key],
                message: response.data.data[key],
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const submitUpdateApi = () => {
      dialogApiVisible.value = false;

      if (editApi.id == null || editApi.id == '') {
        showErrorPopup({
          title: 'Error',
          text: '[Transaction Note] cannot empty',
          message: '[Transaction Note] cannot empty',
        })

        return;
      }

      submitButton.value ? (submitButton.value.disabled = true) : null;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      const payload = {
        id: editApi.id,
        secret: editApi.secret,
        api_token: editApi.api_token,
      };

      ApiService.setHeader();
      ApiService.put(
        `app/entity/client/${clientHash}`,
        payload as any
      )
        .then(() => {
          showSuccessPopup({
            title: 'Update Status',
            text: 'Success'
          });

          // refreshList();

        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            for (const key in response.data.data) {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.data[key],
                message: response.data.data[key],
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const submitUpdateWebhookInfo = () => {
      dialogWebhookVisible.value = false;

      submitButton.value ? (submitButton.value.disabled = true) : null;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      const payload = {
        webhook_auth_config: editWebhookConfig.webhook_auth_config,
        webhook_auth_flow: editWebhookConfig.webhook_auth_flow,
      };
      console.log(payload);

      ApiService.setHeader();
      ApiService.put(
        `app/entity/client/webhook/${clientHash}`,
        payload as any
      )
        .then(() => {
          showSuccessPopup({
            title: 'Update Status',
            text: 'Success'
          });

          // refreshList();

        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            for (const key in response.data.data) {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.data[key],
                message: response.data.data[key],
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const generateCredentials = () => {
      dialogCredentialsVisible.value = false;
      progressUpdate.value = true;

      const payload = {
        type: credentialsType.value,
      };

      ApiService.setHeader();
      ApiService.put(
        `app/entity/client/credentials/${clientHash}`,
        payload as any
      )
        .then(() => {
          showSuccessPopup({
            title: 'Update Status',
            text: 'Success'
          });

          refreshList();
        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            for (const key in response.data.data) {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.data[key],
                message: response.data.data[key],
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          progressUpdate.value = false;
        });
    };

    return {
      moment,
      checkPermission,
      detail,
      // data,
      // isValidating,
      dialogClientVisible,
      dialogApiVisible,
      translate,
      editClient,
      editApi,
      editWebhookConfig,
      showDialogClient,
      showDialogApi,
      submitUpdateClient,
      submitUpdateApi,
      submitUpdateWebhookInfo,
      submitButton,
      handleChangePage,
      showDialogWebhook,
      dialogWebhookVisible,
      channel,
      gateway,
      generateCredentials,
      dialogCredentialsVisible,
      showDialogCredentials,
      progressUpdate,
      authFlow
    };
  },
});
